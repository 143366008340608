// Widescreens
@mixin xxl-screen {
  @media (min-width: 1501px) {
    @content;
  }
}

@mixin xl-screen {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin lg-screen {
  @media (max-width: 900px) {
    //mobile-breakpoint //don't change pls
    @content;
  }
}

@mixin md-screen {
  @media (max-width: 750px) {
    @content;
  }
}

@mixin sm-screen {
  @media (max-width: 600px) {
    @content;
  }
}
@mixin xsm-screen {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin xxsm-screen {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin xxxsm-screen {
  @media (max-width: 300px) {
    @content;
  }
}
