@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.first-row-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;

  gap: 2%;

  @media screen and (max-width: 1100px) {
    flex-flow: column nowrap;
  }
  .dashboard-chart {
    max-width: 95%;
  }

  .chart-display {
    border-radius: 5px;
    width: 50%;

    @media screen and (max-width: 1100px) {
      margin-bottom: 10px;
      width: 100%;
    }

    .intro {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.3rem;
      .dashboard-date-picker {
        display: flex;
        justify-content: flex-start;
      }

      .donuts-chart {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
      }

      .range-date-chosen {
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size18();
        color: $primary-color-dark;
      }
    }
  }
}

.second-row-container {
  margin: 10px 0px;

  .coupon-chart-header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 2%;
  }
}
