@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.tab-component-container {
  border-radius: 5px;
  background-color: $white;
  margin-bottom: 10px;

  .only-padding-top {
    .MuiTabPanel-root {
      padding: 15px 100px !important;
    }
  }
}
.tab-bar {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  gap: 20px;
  padding: 10px;
}

.tab-bar::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.tab-bar button {
  background-color: rgba(29, 33, 45, 0.08);
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 180px;
  border-radius: 8px;
  padding: 10px 20px;
  text-align: center;
  transition: color 0.2s;
  outline: none;
}

.tab-bar button.active {
  background-color: white;
  box-shadow: $active-tab-shadow;
  border-radius: 8px;
  @include font-size18();
  font-weight: 600;
  color: $primary-color-dark;
}
