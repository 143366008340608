@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";

.subscription-action-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .auto-renew-toggle {
    width: 70%;
  }

  .other-actions {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2%;
  }
}

.account-view-outlet {
  margin: 20px 0px;
}

.account-password {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  width: 80%;
  @include md-screen {
    width: 100%;
  }

  .inputs-section {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.3rem;
    @include md-screen {
      display: flex;
      flex-flow: column nowrap;
    }
  }
}
