@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.image-management-container {
  .image-body-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}

.image-component {
  flex-basis: 120px; /* for width: 120px; */

  display: flex;
  flex-flow: column nowrap;
  row-gap: 1%;

  .image-container {
    width: inherit;
    position: relative;
    .selected-image {
      border: 5px solid $primary-color-light !important;
    }
    img {
      display: block;
      width: 100%;
      border: 1px solid $primary-color-light;
      vertical-align: middle;
      border-radius: 9px;
    }

    .image-vector {
      position: absolute;
      bottom: 5px;
      right: 10px;
      cursor: pointer;
      &.checked {
        rotate: -90deg;
      }
    }
  }
  .createdby-username {
    width: 120px;
    color: $secondary-color;
    @include font-size18();
    word-wrap: break-word;
    text-align: center;
    margin: 2px 0px;
    .admin-name {
      position: relative;
    }
    .admin-start {
      position: absolute;
      top: -6px;
    }
  }
}
