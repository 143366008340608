@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.subscription-body-container {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
  max-width: 100%;

  .subscriptionCardSection {
    margin-top: 40px !important;
  }
  .subscriptionCardHeader {
    color: white;
    background-color: #0052a2;
    text-align: center;
  }

  .subscriptionCard {
    // width: 300px;
    margin-right: 5px;
  }

  .priceRow {
    color: #0052a2;
    font-size: 30px;
  }
}
.upgrade-attention-message {
  margin: 10px 0px;
  @include font-size18();
  color: $primary-color-dark;
}

.auto-renew-cancel-message {
  color: $primary-color-dark;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.plan-items-space {
  display: flex;
  flex-flow: row nowrap;
  gap: 2%;
  width: 49%;
  align-items: center;

  @include sm-screen {
    width: 100%;
  }
  .value-space {
    width: 70%;
  }
}

.package-prices-dynamic {
  display: flex;
  flex-flow: row nowrap;
  gap: 1%;

  align-items: center;
}

.subscription-handle-component {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.6rem;
  width: 60%;

  @include xl-screen {
    width: 70%;
  }
  @include lg-screen {
    width: 100%;
  }

  .subscription-items {
    display: flex;
    flex-flow: row wrap;
    gap: 0.6rem;

    @include sm-screen {
      flex-flow: column nowrap;
    }
  }
  .section {
    display: flex;
    flex-flow: column nowrap;
    gap: 0.3rem;
  }
  .section-title {
    @include font-size18;
    font-weight: 700;
  }
  .packages-inputs-dynamic {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 0.3rem;

    .packages-inputs {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      gap: 0.2rem;
      flex: 1;
      @include sm-screen {
        flex-flow: column nowrap;
      }
      .info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        gap: 0.2rem;
        @include xsm-screen {
          flex-flow: column nowrap;
        }
        .info-inputs {
          width: 33.33%;
          @include xsm-screen {
            width: 100%;
          }
        }
      }

      .package-description {
        width: 40%;
        @include sm-screen {
          width: 100%;
        }
      }
      .dynamic-field-delete-icon {
        width: 10%;
      }
    }
  }
  .dynamic-field-option-section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    flex-basis: 5%;
  }
  .width-of-10 {
    width: 10% !important;
  }
}

.swiper-button-next {
  background-image: url(../../../assets/images/swiper_arrow.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 20px !important;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  background-image: url(../../../assets/images/swiper_arrow.svg);
  transform: rotateY(180deg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  width: 20px !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
