@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.coupon-amount-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
}
.coupon-amount-display :first-child {
  display: flex;
  width: 49%;
  justify-content: flex-end;
}

.warning-edit-coupon {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  div {
    @include font-size25();
    background-color: $primary-color-dark;
    color: $white;
    padding: 10px 20px;
    border-radius: $border-radius;
  }
}

.coupon-detail-component {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;

  .coupon-detail-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    word-break: break-all;

    @include lg-screen {
      flex-flow: column nowrap;
      align-items: flex-start;
      gap: 10px;
    }

    div:nth-child(1) {
      width: 50%;
      font-weight: 700;
      @include lg-screen {
        width: 100%;
      }
    }
    div:nth-child(2) {
      width: 50%;
      text-align: right;
      @include lg-screen {
        width: 100%;
        text-align: left;
      }
    }
  }
}
