@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";

body {
  background-color: $body-color;
  padding-top: 60px;
  margin: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
}

.page-container {
  padding: 30px 0px;
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary-color-dark !important;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color-dark;
}
.text-underline {
  text-decoration: underline !important;
}
.link-selected {
  color: $primary-color-dark !important;
}

.margin-up-20 {
  margin: 20px 0px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}
.form-footer-style {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  // margin-left: auto;
  margin-top: 20px;
  justify-content: flex-end;

  @media screen and (max-width: 400px) {
    margin: auto;
    padding-bottom: 10px;
  }
}

.form-footer-elements {
  display: flex;
  flex-flow: row nowrap;
  gap: 10%;
}
.message-space {
  text-align: center;
  margin: 10px;

  .message-content {
    @include font-size18();
    font-style: italic;
    color: red;
  }
}
.total-data-counts {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  @include font-size25();
  margin-bottom: 10px;
  color: $secondary-color;
}
.form-double-inputs-children {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;

  @include sm-screen {
    grid-template-columns: 1fr;
  }
}

.highlighted-amount {
  @include font-size25();
  color: $primary-color-dark;
}

.remove-top-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.action-footer {
  display: flex;
  justify-content: flex-end;
}
.space-between-actions {
  display: flex;
  justify-content: space-between;
}
.absolute-position {
  position: absolute;
}

.flex-column-nowrap {
  display: flex;
  flex-flow: column nowrap;
}

.flex-row-wrap {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.justify-center {
    justify-content: center;
  }
}
.cursor-pointer {
  position: "cursor";
}

.long-tablecell-description {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
