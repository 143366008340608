@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.subscriber-detail-component {
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  .subscription-status {
    border-radius: 9px;
    background-color: $primary-color-light-60;
    color: white;
    padding: 1px 10px;
  }
  .subscriber-info-detail {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;

    .subscriber-detail-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      word-break: break-all;
      @include lg-screen {
        flex-flow: column nowrap;
        align-items: flex-start;
        gap: 10px;
      }

      div:nth-child(1) {
        width: 50%;

        font-weight: 700;

        @include lg-screen {
          width: 100%;
        }
      }
      div:nth-child(2) {
        width: 50%;
        text-align: right;
        @include lg-screen {
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
