@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.print-invoice-margin {
  margin: 5px;
}
.invoice-amount-display {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
}
.invoice-amount-display :first-child {
  display: flex;
  width: 49%;
  justify-content: flex-end;
}
.invoice-detail-component {
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
}

.invoice-content {
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
}
.invoice-detail-header {
  display: flex;
  flex-flow: row nowrap;
  gap: 1%;
  width: 100%;
  height: 150px;

  @include sm-screen {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: unset;
  }

  .invoice-detail-ln {
    display: flex;
    flex-flow: row nowrap;
    width: 70%;
    @include lg-screen {
      flex-flow: column nowrap;
      align-items: flex-start;
      gap: 10px;
      width: 50%;
    }
    @include sm-screen {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .invoice-detail-logo {
      width: 35%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      @include lg-screen {
        width: 100%;
      }
      @include sm-screen {
        width: 100%;
        align-items: center;
      }

      .logo {
        width: 200px;
        img {
          width: 100%;
          height: 80px;
        }
      }
    }
    .invoice-detail-number {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      width: 65%;
      align-items: center;
      height: 100%;

      @include lg-screen {
        width: 100%;
        align-items: flex-start;
      }
      @include sm-screen {
        width: 100%;
        align-items: center;
      }

      div:nth-child(1) {
        @include font-size35;
        font-weight: 700;
      }
    }
  }

  .invoice-detail-company {
    display: flex;
    flex-flow: column nowrap;
    word-break: break-all;
    gap: 2px;
    align-items: flex-end;
    height: 100%;
    width: 30%;

    @include lg-screen {
      width: 50%;
    }
    @include sm-screen {
      width: 100%;
      align-items: center;
    }
  }
}

.invoice-detail-body {
  display: flex;
  flex-flow: column nowrap;
  word-break: break-all;
  width: 40%;
  gap: 5px;

  @include lg-screen {
    width: 100%;
  }

  .body-element {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow-wrap: break-word;
    margin-bottom: 20px;
    @include lg-screen {
      flex-flow: column nowrap;
    }

    div:nth-child(1) {
      width: 50%;
      font-weight: 700;
      color: $primary-color-dark;
      @include lg-screen {
        width: 100%;
      }
    }
    div:nth-child(2) {
      text-align: right;
      width: 50%;
      @include lg-screen {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.invoice-detail-table {
  margin: 20px 0px;
  .invoice-table-container {
    box-shadow: 0px 1px 4px 0px #1d212d26 !important;
  }
}
