@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";

.dialog-title-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.delete-without-reason-prompt {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0px 20px 20px;

  div:nth-child(1) {
    color: $primary-color-dark;
    @include font-size25();
    text-align: center;
    margin-bottom: 10px;
  }

  div:nth-child(2) {
    color: $secondary-color;
    @include font-size18();
    font-weight: 700;
    text-align: center;
  }

  .delete-footer-style {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    // margin-left: auto;
    margin-top: 10px;
    gap: 1%;
  }
}
