@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.session-image-component-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.create-session-image-container {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;

  .image-container {
    flex-basis: 120px; /* width: 120px; */
    cursor: pointer;
    img {
      display: block;
      max-width: 100%;
      vertical-align: middle;
      border-radius: 9px;
    }
  }
}

.form-invite-session-footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2%;
  margin: 10px 0px;
}

.step-label {
  span {
    @include font-size25();
  }
}

.session-invitation-component {
  .dynamic-field-option-section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .dynamic-field-inputs {
    display: flex;
    flex-flow: row nowrap;
    gap: 1%;
    align-items: center;

    .dynamic-field-form-input {
      align-items: center;
      width: 100%;
    }
    .dynamic-field-delete-icon {
      align-items: center;
      width: 5%;
    }
  }
}
.session-status::first-letter {
  text-transform: uppercase;
}

.session-description {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.3rem;
  width: 40%;

  .session-password {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.3rem;
    width: 100%;
    .form-input-wrapper {
      flex: 1;
    }
  }
}
