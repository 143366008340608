@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/variables.scss";
@import "/src/assets/theme/media-queries.scss";
@import "/src/assets/theme/font-sizes.scss";

.authmessages-container {
  border-radius: $authentication-border-radius !important;
  margin: auto;
  box-shadow: $login-shadow !important;
  background-color: $login-bg !important;
  display: flex;
  width: 50%;
  max-width: 800px;
  min-height: 150px;

  @include lg-screen {
    width: 70%;
  }

  @include sm-screen {
    width: 80%;
  }
  .content {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 200px;

    .title {
      @include font-size35();
      font-weight: 700;
    }
    .text {
      text-align: center;
      @include font-size25();
    }
  }
}
