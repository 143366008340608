@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.transparent-bg {
  input,
  div {
    background-color: rgba(255, 255, 255, 0) !important;
  }

  .icon {
    color: $primary-color-dark;
  }
}

.form-input-wrapper {
  .phone-input-style {
    height: 40px !important;
    border: 1px solid $primary-color-dark;
    background-color: white;
    border-radius: 9px;

    width: 100% !important;

    .PhoneInputInput {
      outline: none;
      border: none;
      background: none !important;
      :focus {
        outline: none;
      }
      :focus-within {
        outline: none;
      }
      :focus-visible {
        outline: none;
      }
    }
    .form-control {
      height: 40px !important;
      border: none;
      width: 100%;
    }

    .PhoneInputCountry {
      padding: 0px 10px;
    }

    .PhoneInputCountrySelect {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-size: 14px;
    }

    .flag-dropdown {
      background-color: $white !important;
      border: none;
    }
  }

  //DATE RANGE CSS
  .rdrMonth {
    width: unset !important;
  }

  .rdrDateDisplayWrapper {
    display: none !important;
  }

  .form-upload-section {
    border-radius: 9px;
    height: 300px;
    background-color: $secondary-color;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .multiple-file-upload {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    cursor: pointer;
    color: $primary-color-dark;

    min-width: unset !important;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .payment-card-field {
    border: 1px solid $secondary-color !important;
  }
  .payment-card-field:hover {
    border: 1px solid $primary-color-dark !important;
  }
  .payment-invalid-field {
    border: 1px solid $secondary-color !important;
  }

  .payment-danger-text-style {
    display: none;
  }

  .payment-input {
    border: none !important;
  }

  .required-start {
    color: red !important;
  }
}
.react-date-range-picker {
  width: 100%;
  input {
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $secondary-color;
    color: $secondary-color;
  }
  input:active {
    border: 1px solid $primary-color-dark;
  }
  :focus {
    outline: none !important;
  }
  :focus-within {
    outline: none !important;
  }
  :focus-visible {
    outline: none !important;
  }
}
.react-datepicker-popper button {
  color: white !important;
  margin-top: 4px;
}

.react-datepicker__header {
  background-color: $primary-color-dark !important;
}
.react-datepicker__day {
  color: $primary-color-dark !important;
}
.react-datepicker__day-names {
  background-color: white !important;
}
.react-datepicker__current-month {
  color: white !important;
  margin-bottom: 5px;
}
.react-datepicker__day--selected .react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #d6f3f4 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: #d6f3f4 !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__year-read-view--selected-year,
.react-datepicker__navigation-icon::before {
  border-color: white !important;
}
