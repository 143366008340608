@import "/src/assets/theme/media-queries.scss";

//HEADLINE 1
@mixin font-size70() {
  font-size: 70px;

  @include xxl-screen {
    font-size: 70px;
  }

  @include xl-screen {
    font-size: 65px;
    svg {
      width: 65px;
    }
  }

  @include lg-screen {
    font-size: 55px;
    svg {
      width: 55px;
    }
  }

  @include sm-screen {
    font-size: 50px;
    svg {
      width: 50px;
    }
  }
}

//HEADLINE 2
@mixin font-size45() {
  font-size: 45px;

  @include xxl-screen {
    font-size: 45px;
    svg {
      width: 45px;
    }
  }

  @include xl-screen {
    font-size: 40px;
    svg {
      width: 40px;
    }
  }

  @include lg-screen {
    font-size: 30px;
    svg {
      width: 30px;
    }
  }

  @include sm-screen {
    font-size: 25px;
    svg {
      width: 25px;
    }
  }
}

//HEADLINE 3
@mixin font-size35() {
  font-size: 35px;

  @include xxl-screen {
    font-size: 35px;
    svg {
      width: 35px !important;
    }
  }

  @include xl-screen {
    font-size: 30px;
    svg {
      width: 30px;
    }
  }

  @include lg-screen {
    font-size: 20px;
    svg {
      width: 20px;
    }
  }

  @include sm-screen {
    font-size: 16px;
    svg {
      width: 16px;
    }
  }
}

//HEADLINE 4
@mixin font-size25() {
  font-size: 25px !important;
  @include xxl-screen {
    font-size: 25px !important;
  }

  @include xl-screen {
    font-size: 20px !important;
    svg {
      width: 20px !important;
    }
  }

  @include lg-screen {
    font-size: 20px !important;
    svg {
      width: 20px !important;
    }
  }
  @include sm-screen {
    font-size: 18px !important;
    svg {
      width: 18px !important;
    }
  }
}

//PARAGRAPH
@mixin font-size18() {
  font-size: 18px !important;
  @include xxl-screen {
    font-size: 18px !important;
  }

  @include xl-screen {
    font-size: 16px !important;
    svg {
      width: 16px !important;
    }
  }
  @include lg-screen {
    font-size: 16px !important;
    svg {
      width: 16px !important;
    }
  }
  @include sm-screen {
    font-size: 16px !important;
    svg {
      width: 16px !important;
    }
  }
}
