@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.form-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: $primary-color-dark;
  @include font-size45();
}
.page-title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  @include lg-screen {
    flex-flow: column nowrap;
    margin-bottom: 5px;
  }

  .form-title {
    font-weight: 600;
    @include font-size45();
  }
  .end {
    @include lg-screen {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
  .start {
    display: flex;

    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    margin-bottom: 10px;

    @include lg-screen {
      display: flex;
      width: 100%;
      justify-content: flex-start;
    }

    .icon-space {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .sub-start {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    margin-bottom: 10px;

    .icon-space {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.mini-title {
  @include font-size18();
  color: $secondary-color !important;
}
.main-title {
  color: $primary-color;
  @include font-size45();
}

.title-35 {
  @include font-size35();
}
.title-18 {
  @include font-size18();
}
.sub-title {
  @include font-size25();
}
