@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.load-more-pagination-section {
  margin: 5px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.table-paper {
  width: 100%;
  overflow: hidden;
  box-shadow: $table-shadow !important;

  .table-container-component {
    min-height: 500px;
    max-height: 600px;

    position: relative;

    .initial-table {
      padding: 10px;
    }
    .button-span {
      padding-bottom: unset !important;
      padding-top: unset !important;
    }
  }
}
.table-pagination-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;

  .pagination-item-selected {
    background-color: $primary-color-dark-80;
    text-decoration: underline solid $white 1px;
  }
  .form-control-item {
    width: 20%;

    @include xxxsm-screen {
      margin: 10px 0px;
      width: 100%;
    }
  }

  @include xxxsm-screen {
    flex-flow: column-reverse nowrap;
    align-items: center;
    justify-content: center;
  }
}

.header-section {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 25%;

  @include md-screen {
    flex-flow: column nowrap;
    gap: 15%;
  }

  .search-bar-section {
    width: 30%;
    min-width: 120px;

    @include md-screen {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .button-section {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 15px;

    @include md-screen {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
