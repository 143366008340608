@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.filter-item-container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;

  .filter-item-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .title {
      color: $primary-color-dark;
      font-weight: 700;
      @include font-size18();
    }
  }

  .clear-filter {
    color: black !important;
  }
}

.filter-component-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
