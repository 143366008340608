@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";

.page-not-found {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: $primary-color;
  :nth-child(1) {
    @include font-size70();
  }
  :nth-child(2) {
    @include font-size25();
  }
}
.page-no-permission {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: $red;
  @include font-size25();
}
.no-connection-container {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-connection {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    h4 {
      color: $primary-color-dark;
    }
    .no-connection-sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h6 {
        color: $secondary-color;
      }
    }
  }
}
