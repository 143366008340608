@import "/src/assets/theme/color.scss";
@import "/src/assets/theme/font-sizes.scss";
@import "/src/assets/theme/variables.scss";

.navigationBar {
  top: 0;
  width: 100%;
  height: 65px;
  position: fixed;
  background: $blue-primary !important;
  z-index: 1000 !important;

  .navigationBar-container {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;

    max-width: 2000px;
    margin: 0px auto;
  }

  .logo {
    display: flex;
    width: 30%;
    align-items: center;

    flex-flow: row nowrap;
    color: $primary-color-dark;
    gap: 10%;
    font-weight: 500;
    margin: 0 5%;

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    @media screen and (max-width: 500px) {
      width: 60%;
    }
  }

  .side-items {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    align-items: center;
    gap: 2%;
    margin: 0 5%;
  }
}

.drawer-header-container {
  .text {
    width: 50%;
    padding: 0px 16px;
    color: $primary-color-dark;
    font-weight: 500;

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }
  }
  .burger {
    width: 50%;
  }
}

.landing-navigation-bar {
  top: 0;
  width: 100vw;
  height: 65px;
  position: fixed;
  background: $white !important;
  box-shadow: 0px 1px 4px $shadow;
  z-index: 1000 !important;
  .landing-navigation-bar-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: 2000px;
    margin: 0px auto;
    padding: 0px 40px;

    @media only screen and (max-width: $mobile_breakpoints) {
      padding: 0px 20px;
    }
    @media only screen and (max-width: 400px) {
      padding: 0px 10px;
    }
  }
  .landing-logo {
    display: flex;
    width: 30%;
    align-items: center;

    flex-flow: row nowrap;
    color: $primary-color-dark;
    gap: 10%;
    font-weight: 500;

    @media screen and (max-width: $mobile_breakpoints) {
      width: 70%;
    }

    img {
      width: 100px;
      height: 30px;
      cursor: pointer;
    }

    .landing-burger {
      @media screen and (min-width: $mobile_overbreakpoints) {
        display: none;
      }
    }
  }
  .nav-links {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: $mobile_overbreakpoints) {
      display: none;
    }
  }

  .side-items {
    width: 20%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    align-items: center;
    gap: 2%;

    @media screen and (max-width: 800px) {
      width: 10%;
    }
  }
}
.navigations-bar-outlet {
  height: calc(100vh - 100px);
}
.portal-component {
  background: $white;

  width: 90%;
  margin: 20px auto;

  &.sideOpen {
    padding-left: 300px;
    width: 96%;
    margin: 20px 0px;
    @media only screen and (max-width: 800px) {
      padding: 0;
    }
  }
}

.authentication-component {
  background: linear-gradient(172deg, #0052a2, rgba(88, 104, 240, 0) 68.45%);
}

.landing-background {
  background: linear-gradient(172deg, #0052a2, rgba(88, 104, 240, 0) 68.45%);
}

.landing-footer-component {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  background: $primary-color-dark !important;
  box-shadow: 0px 1px 4px $shadow;

  .landing-footer-container {
    width: 95%;
    margin: 20px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
      flex-flow: column nowrap;
    }

    .footer-element {
      color: $white;
      display: flex;

      width: 25%;
      flex-flow: column nowrap;

      @media screen and (max-width: 600px) {
        width: 100%;
      }

      .title {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        @include font-size25();
        margin-bottom: 30px;
        height: 30px;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
          margin-bottom: 0px;
        }
      }

      .body {
        @include font-size25();
        width: 100%;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;

        a {
          color: white;
        }
      }
      .social-media {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        gap: 10%;

        @media screen and (max-width: 600px) {
          justify-content: unset;
        }
      }

      .support-footer-element {
        display: block;
        width: fit-content;
        justify-content: center;
        align-self: center;

        @media screen and (max-width: 600px) {
          justify-content: unset;
          align-self: unset;
        }
      }
    }

    .social-media-element {
      align-items: flex-end;

      @media screen and (max-width: 600px) {
        align-items: unset;
      }
    }
  }
  .divider-section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 95%;
    margin: 0px auto;
  }
  .landing-page-footer-divider {
    .MuiDivider-root {
      color: $white !important;
      background-color: $white !important;
      background: $white !important;
    }
  }
  .footer-footer-container {
    margin: 20px auto;
    width: 95%;
    @include font-size18();
    color: white;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 600px) {
      flex-flow: column-reverse nowrap;
      justify-content: center;
    }

    .terms-and-conditions {
      margin-bottom: 5px;
      width: 70%;
      display: flex;
      justify-content: flex-end;
      flex-flow: row nowrap;
      gap: 3%;
      align-items: center;
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }
  }
}

.attention-note {
  display: flex;
  align-items: center;

  height: 30px;
  background: $primary-color-dark !important;
  color: $white;
  position: fixed;
  top: 60px;
  width: 100%;
  padding: 0px 10px;
  z-index: 1000 !important;

  .message {
    flex: 1 1 0px;
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    align-items: center;
    animation: scrollText 40s infinite linear;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @keyframes scrollText {
    0% {
      left: 0;
    }
    50% {
      left: calc(100% - 500px);
    }
    100% {
      left: 0;
    }
  }

  .message:hover {
    animation-play-state: paused;
  }
}
